import React from 'react';
import Header_img from '../../images/HEADER2.png'

const About3 = () => {
    return(
        <div className="about" id="about">
            <div className="image">
                <img src={Header_img} alt="" />
            </div>

            <div className="content">
                <h3>שיעורים פרטיים</h3>
                <p>
                אנו מנגישים לתלמידינו את חומרי הלימוד בכל המקצועות דרך למידה בחוויה אחרת ושמים דגש על פיתוח מיומנויות למידה. בשיעורים הפרטיים הילדים ילמדו להיות לומדים עצמאיים שידעו להשתמש במאגרי המידע שתיווכו להם ולהפיק מהם למידה משמעותית. בשיעורים הפרטיים התלמידים ילמדו בצורה איכותית את החומר הנלמד בבית הספר ע"י הוראה ברמה הגבוהה והמקצועית ביותר. בהזמנת שיעור פרטי, קיימת אפשרות להגעה עד בית התלמיד.
                </p>
                <h3>קבוצות למידה</h3>
                <p>
                בקבוצות הלמידה ילדיכם ייחשפו לכלים טכנולוגיים מתקדמים שיסייעו להם ללמוד ולנהל את תהליך הלמידה שלהם בעצמם! באמצעות קבוצות הלמידה, ילדיכם יתקדמו ויצמצמו פערים לימודיים. החזון של "בית הספר של דוראל" הוא לאפשר לתלמידים להיות לומדים עצמאיים ע"י הכוונה מקצועית ובאמצעות כלים חדשניים המוכחים כיום כמוצלחים ביותר בתחום הפדגוגיה. בנוסף, בקבוצות הלמידה ילדיכם יפתחו כישורים חברתיים באמצעות פעילויות העוסקות בדינמיקה קבוצתית וידעו להתמודד עם תסכולים הנובעים מקשיי למידה הייחודיים לכל אחד ואחת. בקבוצות הלמידה ילדיכם יתחנכו לערכים של מוסר, אכפתיות והתחשבות באחר.
                </p>
                 {/* <a href="/about"><button className='btn'>רוצים ללמוד עוד?</button></a> */}
            </div>
        </div>

    )
}

export default About3;