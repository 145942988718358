import React from 'react';
import About1 from './abouts/About1';
import About2 from './abouts/About2';
import About3 from './abouts/About3';

const About = () => {
    return(
        <section className="about" id="about">  
        <About1/>
        <About2/>
        <About3/>


    </section>

    )
}

export default About;