import React from 'react';

import Course1 from './courses/Course1';
import Course2 from './courses/Course2';
import Course3 from './courses/Course3';
import Course4 from './courses/Course4';
import Course5 from './courses/Course5';
import Course6 from './courses/Course6';


const Course = () => {
    return(
        <section className="course" id="course">
    
        <h1 className="heading">תכניות לימוד</h1>

        <div className="box-container">

        <Course1 />
        <Course2 />
        <Course3 />
        <Course4 />
        <Course5 />
        <Course6 />

        </div>

    </section>
        
    )
}

export default Course;