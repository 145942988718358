import React from 'react';
import Course1_img from '../../images/course1.jpg'

const Course1 = () => {
    return(
        <div className="box">
        <img src={Course1_img} alt="" />
        {/* <a href="/" className="more">לפרטים</a> */}
        <div className="content">
            <div className="stars">
                {/* <i className="fas fa-graduation-cap"></i> */}
            </div>
            <div>
                <a href="/" className="title">הכנה לכיתה א'</a>
                <p>
                מוכנות לכיתה א' הינה תהליך של רכישת מיומנויות ואימון המרחש כבר בגן טרום חובה. "בית הספר של דוראל", יכין את ילדיכם בצורה הטובה ביותר לכיתה א'.
                </p>
                {/* <div className="info">
                    <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                    <h3><i className="fa fa-users"></i>קבוצת </h3>
                    <h3><i className="fa fa-user"></i>יחידים </h3>
                </div> */}
            </div>

        </div>
        </div>

    )
}

export default Course1;



