import React from 'react';
import Header_img from '../../images/HEADER.png'

const About2 = () => {
    return(
        <div className="about" id="about">

            <div className="image">
                <img src={Header_img} alt=""/>
            </div>

        <div className="content">
            <h3>הילדים שלנו</h3>
            <p><q><strong>הצלחה היא היכולת לעבור מכישלון לכישלון בלי לאבד את ההתלהבות</strong></q> (וינסטון צ'רצ'יל) </p>
            <p>
            הדבר החשוב ביותר עבורנו הוא שילדינו יפיקו מעצמם את המיטב. הצלחה היא לדעת שהגענו ליעד המקסימלי שלנו בתחושה של משמעות. הורים רבים שואלים "איך נוכל לגדל ילד מצליח?" ופחות "איך נוכל לגדל ילד מאושר?". אנו מאמינים שהמתכון לאושר הוא תחושת ההצלחה, ושכל ילד צריך מבוגר אחד שיאמין בו בכדי להצליח. "בית הספר של דוראל" יאתר את החוזקות של ילדיכם בכדי להעניק להם תחושת הצלחה, ביטחון, אמון עצמי ורצון לשאוף למצוינות!
            </p>
        </div>


    </div>

    )
}

export default About2;