import React from 'react';
import Course2_img from '../../images/course2.jpg';

const Course2 = () => {
    return(
        <div className="box">
        <img src={Course2_img} alt="" />
        {/* <a href="#" className="more">לפרטים</a> */}
        <div className="content">
            <div className="stars">
                {/* <i className="fas fa-solid fa-comments"></i> */}
            </div>
            <div>
                <a href="/" className="title">שפה</a>
                <p>
                השפה הינה כלי לתקשורת בין בני אדם. היא האמצעי שלנו להתבטא ולהבין את סביבתנו. השפה היא הכוח שלנו לשנות ולהניע דברים בעולם.
                </p>
                {/* <div className="info">
                    <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                    <h3><i className="fa fa-users"></i>קבוצת </h3>
                    <h3><i className="fa fa-user"></i>יחידים </h3>
                </div> */}
            </div>

            </div>
        </div>

    )
}

export default Course2;



