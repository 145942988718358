import React, { useState } from 'react';
import APIService from './APIService'
import Contact_img from '../images/contact-img.png'


const Contact = (props) => {
    // const [title, setTitle] = useState('')
    // const [body, setBody] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhone] = useState('');
    const [message, setMessage] = useState('');
    // const [emailSent, setEmailSent] = useState(false);

    // const POST = (data) =>{
    //     APIService.SubmitContact(data)}
        // .then(async(response) => (response.status))
        // {
            // const json = await response.json()
            // console.log(json.status)
        // })
        // .catch(error => console.log('error',error))
    // }

    // console.log("name: ", name);
    // console.log("email: ",email);
    // console.log("phoneNumber: ",phoneNumber);
    // console.log("message: ",message);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name && phoneNumber){
        try {
        //   let res = await fetch(`http://localhost:5000/contact`,{
        //     'method':'POST',
        //      headers : {
        //     'Content-Type':'application/json'
        //     },
        //     body: JSON.stringify({name, email, phoneNumber, message}),
        //   });
        let res = await APIService.SubmitContact(({name, email, phoneNumber, message}))

        // let resJson = await res.json();
          if (res.status === 200) {
            setName('')
            setEmail('')
            setPhone('')
            setMessage('')
            // setEmailSent(true)
            alert("קבלנו את הפרטים, נחזור אליכם בהקדם");
          } else {
            alert("קרתה שגיאה, ולא הצלחנו לקבל את הפרטים, אנא צור קשר טלפוני");
          }
        } catch (err) {
          console.log(err);
        }
    }else{alert("אנא מלאו שם ומספר טלפון");}
      };

    return (

    <section className="contact" id="contact">
        <h1 className="heading">השאר פרטים</h1>

        <div className="row">

            <form id="contact-form">
                <input type="text" id="name" placeholder="שם מלא" className="box" value={name} onChange={e => setName(e.target.value)} />
                <input type="email" id="email" placeholder="מייל" className="box" value={email} onChange={e => setEmail(e.target.value)} />
                {/* <input type="password" placeholder="סיסמה" className="box" /> */}
                <input type="number" id="number" placeholder= "מספר נייד" className="box" value={phoneNumber} onChange={e => setPhone(e.target.value)} />
                <textarea name="" id="message" cols="30" rows="10" className="box address" placeholder="הודעה" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                {/* <input type="sumbit" className="btn" value="שלח" /> */}
                <button type="submit" className="btn" onClick={handleSubmit}>שלח</button>
                {/* <span className={emailSent ? 'visible' : null}>Thank you for your message, we will be in touch in no time!</span> */}
            </form>

            <div className="image">
                <img src={Contact_img} alt="" />
            </div>
            {/* <div className="message">{message ? <p>{message}</p> : null}</div> */}
        </div>
    </section>
        
    )}


export default Contact;