import React from 'react';
export default function FacebookLink() {
    return(
        <div>
        <a
        href="https://www.facebook.com/doreleilat?mibextid=ZbWKwL"
        className="facebookLink_float"
        target="_blank"
        rel="noopener noreferrer"
        >
          
        <i className="fa fa-facebook facebookLink-icon"></i>
      </a>
        </div>
        
      
    )
}
