import React from 'react';

const Call = () => {
    return(
        <div>
      <a
        href="tel:0532335757"
        className="call_float"
        target="_blank"
        rel="noopener noreferrer"
        >        
        <i className="fa fa-phone phone-icon"></i>
      </a>
        </div>
        
      
    )
}

export default Call;