import React from 'react';
import Course5_img from '../../images/course5.png'

const Course5 = () => {
    return(
        <div className="box">
                <img src={Course5_img} alt="" />
                {/* <a href="#" className="more">לפרטים</a> */}
                <div className="content">
                    <div className="stars">
                        {/* <i className="fas fa-star"></i> */}
                    </div>
                    <div>
                        <a href="/" className="title">אימונים ריגשיים</a>
                        <p>
                        <strong>לא רק לימודים!</strong> אנו מבינים את הקושי הרב שיש במימון טיפולים רגשיים לילדים ולכן דואגים, מעבר למענה הפדגוגי, להעניק לילדיכם את התמיכה הרגשית לה הם זקוקים.
                        </p>
                        {/* <div className="info">
                            <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                            <h3><i className="fa fa-users"></i>קבוצת </h3>
                            <h3><i className="fa fa-user"></i>יחידים </h3>
                        </div> */}
                    </div>

                </div>
            </div>

    )
}

export default Course5;



