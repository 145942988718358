import React from 'react';
import Home from './Home';
import About from './About';
import Course from './Course';
import Teacher from './Teacher';
import Contact from './Contact';


const Sections = () => {
    return(
        <div>
        <Home/>

        <About/>
    
        <Course/>
    
        <Teacher/>

        <Contact/>
  
       </div>
    )
}

export default Sections;