import React from 'react';
import Course6_img from '../../images/course6.jpg'

const Course6 = () => {
    return(
        <div className="box">
                <img src={Course6_img} alt="" />
                {/* <a href="#" className="more">לפרטים</a> */}
                <div className="content">
                    <div className="stars">
                        {/* <i className="fas fa-star"></i> */}
                    </div>
                    <div>
                        <a href="/" className="title">העשרה חינוכית</a>
                        <p>מעבר למקצועות הליבה, אנו מעבירים תכניות למידה והעשרה של אשכולות חשיבה שונים במסגרת מערכת דידקטית המותאמת אישית לכל תלמיד ותלמיד ומשקפת את עולם הערכים והאמונות של התלמידים.</p>
                        {/* <div className="info">
                            <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                            <h3><i className="fa fa-users"></i>קבוצת </h3>
                            <h3><i className="fa fa-user"></i>יחידים </h3>
                        </div> */}
                    </div>

                </div>
            </div>

    )
}

export default Course6;



