import React from 'react';
import Header from '../images/HEADER_app.png';

const Home = () => {
    return(
    <section className="home" id="home">
        <h1>בית הספר של דוראל</h1>
        <p>
            הוראה דיפרנציאלית ואישית לשיפור אסטרטגיות ללמידה  קבוצתית ועצמאית, הוראה מותאמת אישית בחוויה מהנה ומאתגרת, תכנים מגוונים בכלים החדשניים ביותר בקבוצות וביחידים.
        </p>
        {/* <a href="#"><button className="btn">השאר פרטים</button></a> */}

        <div className="home_pic">
                <img src={Header} alt="" />
            </div>

        <div className="shape"></div>
    </section>
    )
}

export default Home;