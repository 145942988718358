import React from 'react';

const Teacher = () => {
    return(
        <section className="teacher" id="teacher">
        <h1 className="heading">הצוות שלנו</h1>

        <p>אנו מזמינים אותכם ליצור קשר להכיר את צוות המורים המסור שלנו</p>

        {/* <a href="#"><button className="btn">הכירו את המורים שלנו</button></a> */}


    </section>   
    )
}

export default Teacher;