import React from 'react';
import Header from './components/Header';
import LoginForm from './components/LoginForm';
import Sections from './components/Sections';
import Whatsapp from './components/Whatsapp';
import FacebookLink from './components/FacebookLink';
import Call from './components/Call';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <LoginForm />
      <Sections />
      <Whatsapp />
      <Call />
      <FacebookLink />
    </div>
  );
}

export default App;
