import React from 'react';
import Header_img from '../../images/HEADER3.png'

const About1 = () => {
    return(
        <div className="about">
            <div className="image">
                <img src={Header_img} alt="" />
            </div>

            <div className="content">
                <h3>למה אנחנו?</h3>
                <p>
                אחרי ניסיון רב ומוכח של יותר משבע שנים ומפגשים עם מאות תלמידים מהחינוך הרגיל והמיוחד, נוכחתי לגלות שיש צורך אמיתי להקנות כלים נוספים לילדינו, מעבר למערכת החינוך הפורמלית.
                </p>
                <p>
                אנו מציעים הוראה דיפרנציאלית בה כל תלמיד יוכל למצוא את מקומו בשיעורים ולקבל חומרים בהירים ונגישים שהוכנו במיוחד עבורו! השיעורים כוללים שיפור אסטרטגיות ללמידה עצמאית, הוראה מותאמת אישית בחוויה מהנה ומאתגרת ותכנים מגוונים בכלים החדשניים ביותר בקבוצות וביחידים.
                </p>
            </div>
        </div>

    )
}

export default About1;