import React from 'react';
import Course3_img from '../../images/course3.jpg'

const Course3 = () => {
    return(
        <div className="box">
        <img src={Course3_img} alt="" />
        {/* <a href="#" className="more">לפרטים</a> */}
        <div className="content">
            <div className="stars">
                {/* <i className="fas fa-calculator-simple"></i> */}
            </div>
            <div>
                <a href="/" className="title">חשבון</a>
                <p>
                לימודי מתמטיקה הם הבסיס לכלל המקצועות הריאליים של המחר. פיתוח חשיבה מתמטית בגיל צעיר, יוביל את ילדיכם להצלחות מרחיקות לכת בחייהם הבוגרים.                </p>
                {/* <div className="info">
                    <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                    <h3><i className="fa fa-users"></i>  קבוצת </h3>
                    <h3><i className="fa fa-user"></i>  יחידים </h3>
                </div> */}
            </div>

            </div>
        </div>

    )
}

export default Course3;



