export default class APIService{
    // Insert an article
    static SubmitContact(data){
        return fetch(`api/contact`,{
            'method':'POST',
             headers : {
            'Content-Type':'application/json'
      },
      body:JSON.stringify(data),
    });
    // .then(response => response.json())
    // .catch(error => console.log(error))
    }
    
}

