// import { userRef, useState, useEffect } from 'react';
// import React from "react";

// class LoginForm extends React.Component {

//     render(){
//         const userRef = useRef();
//         const errRef = useRef();
        
//         const [user, setUser ] = userState('');
//         const [pwd, setPwd ] = userState('');
//         const [userMsg, setErrMsg ] = userState('');
//         const [success, setSuccess ] = userState('false');

//         useEffect(() => {
//             userRef.current.focus();
//         }, [])

//         useEffect(() => {
//             setErrMsg('');
//         }, [user, pwd])


//         return (
//             <div className="login-form">

//                 <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

//                 <form action="">
//                     <h3>התחבר</h3>
//                     <input type="email" 
//                     id="username"
//                     ref={userRef}
//                     onChange={(e) => setUser(e.target.value)}
//                     value={user}
//                     placeholder="שם משתמש" 
//                     className ="box" 
//                     />
//                     <input type="password" placeholder="סיסמה" className ="box" />
//                     <p>שחזור סיסמה <a href="#">לחץ כאן</a></p>
//                     <p>תלמידים חדשים?<a href="#">הירשם כאן!</a></p>
//                     <input type="sumbit" className="btn" value="login" />
//                     <i className="fas fa-times"></i>
//                 </form>
//             </div>

//         )}
//     }

// export default LoginForm;

// import { userRef, useState, useEffect } from 'react';
import React from "react";

const LoginForm = () => {
        return (
            <div className="login-form">
                <form action="">
                    <h3>התחבר</h3>
                    <input type="email" 
                    id="username"
                    placeholder="שם משתמש" 
                    className ="box" 
                    />
                    <input type="password" placeholder="סיסמה" className ="box" />
                    <p>שחזור סיסמה <a href="/">לחץ כאן</a></p>
                    <p>תלמידים חדשים?<a href="/">הירשם כאן!</a></p>
                    <input type="sumbit" className="btn" value="התחבר" />
                    <i className="fas fa-times"></i>
                </form>
            </div>

        )}

export default LoginForm;