import React from 'react';
import Logo from '../images/52.png';

const Header = () => {
    return(
        <header className="App-header">
            <div id="menu" className="fas fa-bars">

            </div>

            <a href="/" className="logo"><img src={Logo} alt="" /></a>
        

            <nav className="navbar">
                <ul>
                    <li><a className="active" href="#home">בית</a></li>
                    <li><a href="#about">עלינו</a></li>
                    <li><a href="#course">תכניות לימוד</a></li>
                    <li><a href="#teacher">צוות</a></li>
                    <li><a href="#contact">צור קשר</a></li>
                </ul>
            </nav>
            <div id="login" className="fas fa-user-circle"></div>

        </header>
    )
}

export default Header;