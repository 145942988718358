import React from 'react';
import Course4_img from '../../images/course4.jpg'

const Course4 = () => {
    return(
        <div className="box">
                <img src={Course4_img} alt="" />
                {/* <a href="#" className="more">לפרטים</a> */}
                <div className="content">
                    <div className="stars">
                        {/* <i className="fas fa-regular fa-e"></i> */}
                    </div>
                    <div>
                        <a href="/" className="title">אנגלית</a>
                        <p>
                        "ידע הוא כוח" - ובעיקר באנגלית! אנגלית הינה השפה הפופולארית והנדרשת ביותר בעולם. "בית הספר של דוראל" מכיר בחשיבות רכישת השפה האנגלית ככלי להצלחה בעולם העבודה של המחר.
                        </p>
                        {/* <div className="info">
                            <h3><i className="far fa-clock"></i> 1-3 שעות</h3>
                            <h3><i className="fa fa-users"></i>קבוצת </h3>
                            <h3><i className="fa fa-user"></i>יחידים </h3>
                        </div> */}
                    </div>

                </div>
            </div>

    )
}

export default Course4;



